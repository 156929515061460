import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import useNotifications from "@/composables/useNotifications";
import i18n from '@/libs/i18n';
import useCommon from '@/views/organization/useCommon'
import realmConnection from '@/views/habit/realm'

export default function useLocationList() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { handleError } = useCommon()
  const { invokeFunction, updateItem } = realmConnection()
  const userData = store.state?.userStore?.userData
  const locations = ref([])
  const zoneAgencyFilter = JSON.parse(localStorage.getItem("clientData") || '{}').zone_agency_filter
  const commitmentFunctionality = JSON.parse(localStorage.getItem("clientData") || '{}').commitment_functionality
  const collection = 'location'

  // Table Headers
  const tableColumns = computed(() => {
    const headers = [
      { key: 'location', label: i18n.t('message.tableHeader.location'), sortable: true },
      { key: 'Actions', label: i18n.t('message.tableHeader.actions') },
    ]
    if (zoneAgencyFilter) {
      headers.splice(1, 0, { key: 'zone', label: i18n.t('message.tableHeader.zone'), sortable: true })
      if (!commitmentFunctionality) {
        // Insert in the position after 'zone'
        headers.splice(2, 0, { key: 'agency', label: i18n.t('message.tableHeader.agency'), sortable: true })
      }
    }
    return headers
  })

  const totalLocations = ref(0)
  const perPage = ref(10)
  const currentPage = ref(1)
  const perPageOptions = [5, 10, 15, 20, 25, 30, 40, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('location')
  const isSortDirDesc = ref(false)
  const isLoading = ref(true)

  const fetchLocations = async () => {
    isLoading.value = true

    try {
      const input = {
        limit: perPage.value,
        total: currentPage.value === 1,
        offset: (currentPage.value * perPage.value) - perPage.value,
        query: {},
        search: searchQuery.value,
        sortBy: sortBy.value,
        sortOrder: isSortDirDesc.value ? -1 : 1,
        client_id: ( userData.role != "admin" ) ? userData.client.$oid : null
      }
    
      const items = await invokeFunction({ name: 'locationsQueryWithOffset', arg: input })

      locations.value = items?.locations || []
      if (currentPage.value === 1) totalLocations.value = items?.totalRecords || 0
    } catch (error) {
      console.log(error)
      handleError({ error, defaultMessage: i18n.t('message.location_list_fetch_error') })
    } finally {
      isLoading.value = false
    }
  }

  const deleteLocation = async (id) => {
    try {
      const query = { _id: id }
      const action = { $set: { deleted: true } }

      await updateItem({ collection, query, action })

      fetchLocations()
      showSuccessMessage(i18n.t('message.location_remove'))
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.something_went_wrong'))
    }
  }

  const showingMessage = computed(() => {
    const from = ((currentPage.value * perPage.value) - perPage.value) + (locations.value.length ? 1 : 0)
    const to = locations.value.length + ((currentPage.value * perPage.value) - perPage.value)

    return i18n.tc('message.paginationText', 0, { from: from, to: to, total: totalLocations.value })
  })

  watch([currentPage, perPage], () => {
    fetchLocations()
  })

  watch([searchQuery, sortBy, isSortDirDesc], () => {
    currentPage.value = 1
    fetchLocations()
  })

  return {
    fetchLocations,
    tableColumns,
    perPage,
    currentPage,
    perPageOptions,
    totalLocations,
    showingMessage,
    searchQuery,
    sortBy,
    isSortDirDesc,
    locations,
    deleteLocation,
    isLoading,
  }
}